
import * as konfettiRuntime$3d7m1VqGQC from '/app/providers/konfetti.ts'
import * as ipxRuntime$5zqRufdYjf from '/app/node_modules/.pnpm/@nuxt+image@1.2.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.22.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['konfetti']: { provider: konfettiRuntime$3d7m1VqGQC, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$5zqRufdYjf, defaults: {} }
}
        